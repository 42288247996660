import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { Col } from "react-bootstrap"
import Button from "react-bootstrap/esm/Button"
import Row from "react-bootstrap/esm/Row"
import { Page } from "../components/Page"
import { Draft, listDrafts } from "../db"

export default function Drafts() {
  const [drafts, setDrafts] = useState([] as Draft[])

  useEffect(() => {
    listDrafts().then(drafts => setDrafts(drafts))
  }, [])

  return (
    <>
      <Page>
        <h3>Drafts</h3>

        <Row style={{ marginBottom: "20px" }}>
          <Link to="/editor/">
            <Button variant="outline-primary" id="button-addon2" type="button">
              New document
            </Button>
          </Link>
        </Row>
        {drafts.map(draft => (
          <Row key={draft.id} style={{ marginBottom: "5px" }}>
            <Col xs={2} md={1}>
              <Link to={`/editor/${draft.id}`}>
                <Button
                  variant="outline-primary"
                  id="button-addon2"
                  type="button"
                >
                  Edit
                </Button>
              </Link>
            </Col>
            <Col
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              <h5 style={{ lineHeight: "200%" }}>{draft.title}</h5>
            </Col>
          </Row>
        ))}
      </Page>
    </>
  )
}
